<template>
  <v-card-text>
    <v-row>
      <v-col class="pb-0 pt-2" cols="6" :md="is_edit ? 4 : 6">
        <v-select
          v-if="!is_edit"
          v-model="mainTemplate.category"
          dense
          label="Категория"
          :items="categoryList"
          @change="initializeTable(), onChangeCategory()"
        />
        <p v-else>Категория: {{ mainTemplate.category_name }}</p>
      </v-col>
      <v-col class="pb-0 pt-2" cols="6" :md="is_edit ? 4 : 6">
        <v-select
          v-if="!is_edit"
          v-model="mainTemplate.price_type"
          label="Цена по дням недели"
          dense
          :items="tariffTypes"
        />
        <p v-else>
          Цена:
          {{
            mainTemplate.price_type == "weekdays"
              ? "По дням недели"
              : "За период"
          }}
        </p>
      </v-col>
      <v-col v-if="is_edit" class="pb-0 pt-2" cols="6" md="4">
        <p>Дата создания: {{ mainTemplate.created_at | convertDate }}</p>
      </v-col>
      <v-col>
        <v-data-table
          :headers="computedHeaders"
          :items="computedItems"
          :items-per-page="15"
          class="elevation-1"
          :key="reactTrigger"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="weekDialog" max-width="900px">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>
                {{ editedWeekItem.name }}
              </h2>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.monday_price"
                label="Понедельник"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.tuesday_price"
                label="Вторник"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.wednesday_price"
                label="Среда"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.thursday_price"
                label="Четверг"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.friday_price"
                label="Пятница"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.saturday_price"
                label="Суббота"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="editedWeekItem.sunday_price"
                label="Воскресенье"
              />
            </v-col>
            <v-col>
              <v-btn @click="saveWeek" :disabled="disabledBtn">
                Сохранить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="monthDialog" max-width="900px">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>
                {{ editedMonthItem.name }}
              </h2>
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="jan"
                v-model.number="editedMonthItem.jan"
                label="Январь"
                :rules="[() => !!editedMonthItem.jan]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="feb"
                v-model.number="editedMonthItem.feb"
                label="Февраль"
                :rules="[() => !!editedMonthItem.feb]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="mar"
                v-model.number="editedMonthItem.mar"
                label="Март"
                :rules="[() => !!editedMonthItem.mar]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="apr"
                v-model.number="editedMonthItem.apr"
                label="Арель"
                :rules="[() => !!editedMonthItem.apr]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="may"
                v-model.number="editedMonthItem.may"
                label="Май"
                :rules="[() => !!editedMonthItem.may]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="jun"
                v-model.number="editedMonthItem.jun"
                label="Июнь"
                :rules="[() => !!editedMonthItem.jun]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="jul"
                v-model.number="editedMonthItem.jul"
                label="Июль"
                :rules="[() => !!editedMonthItem.jul]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="aug"
                v-model.number="editedMonthItem.aug"
                label="Август"
                :rules="[() => !!editedMonthItem.aug]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="sep"
                v-model.number="editedMonthItem.sep"
                label="Сентябрь"
                :rules="[() => !!editedMonthItem.sep]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="oct"
                v-model.number="editedMonthItem.oct"
                label="Октябрь"
                :rules="[() => !!editedMonthItem.oct]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="nov"
                v-model.number="editedMonthItem.nov"
                label="Ноябрь"
                :rules="[() => !!editedMonthItem.nov]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="dec"
                v-model.number="editedMonthItem.dec"
                label="Декабрь"
                :rules="[() => !!editedMonthItem.dec]"
              />
            </v-col>
            <v-col>
              <v-btn @click="saveMonth()" :disabled="disabledBtn">
                Сохранить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { TariffsApiUrls } from "@/services/accommodationRequests/tariffs.api.js";
import moment from "moment";

export default {
  name: "FirstStep",
  props: {
    categoryList: Array,
    mainTemplate: Object,
    is_edit: Boolean
  },
  data: () => ({
    reactTrigger: 0,
    tariffTypes: [
      { text: "За период", value: "period" },
      { text: "По дням ", value: "weekdays" }
    ],
    priceTypes: [
      { name: "Основной тариф", value: "base" },
      { name: "Доп. место взрослый", value: "additional" },
      { name: "Доп. место детский", value: "child" },
      { name: "Одиночное бронирование", value: "single" },
      { name: "По часам", value: "time" },
      { name: "По взрослым часам", value: "time_adult" },
      { name: "По детским часам", value: "time_child" },
      { name: "По койкам", value: "koika" },
      { name: "Дети без места", value: "child_without_place" }
    ],
    disabledBtn: false,
    editedWeekItem: {
      name: null,
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null
    },
    editedMonthItem: {
      name: null,
      month_1: null,
      month_2: null,
      month_3: null,
      month_4: null,
      month_5: null,
      month_6: null,
      month_7: null,
      month_8: null,
      month_9: null,
      month_10: null,
      month_11: null,
      month_12: null
    },
    weekDialog: false,
    monthDialog: false
  }),
  validations: {
    editedMonthItem: {
      month_1: { required },
      month_2: { required },
      month_3: { required },
      month_4: { required },
      month_5: { required },
      month_6: { required },
      month_7: { required },
      month_8: { required },
      month_9: { required },
      month_10: { required },
      month_11: { required },
      month_12: { required }
    }
  },
  filters: {
    convertDate(value) {
      return moment(value * 1000).format("DD.MM.YYYY HH:mm");
    }
  },
  computed: {
    computedHeaders() {
      return this.mainTemplate.price_type === "weekdays"
        ? [
            { text: "", value: "name" },
            { text: "ПН", value: "monday_price" },
            { text: "ВТ", value: "tuesday_price" },
            { text: "СР", value: "wednesday_price" },
            { text: "ЧТ", value: "thursday_price" },
            { text: "ПТ", value: "friday_price" },
            { text: "СБ", value: "saturday_price" },
            { text: "ВС", value: "sunday_price" },
            { text: "Действие", value: "actions", sortable: false }
          ]
        : [
            { text: "", value: "name" },
            { text: "Январь", value: "jan" },
            { text: "Февраль", value: "feb" },
            { text: "Март", value: "mar" },
            { text: "Апрель", value: "apr" },
            { text: "Май", value: "may" },
            { text: "Июнь", value: "jun" },
            { text: "Июль", value: "jul" },
            { text: "Август", value: "aug" },
            { text: "Сентябрь", value: "sep" },
            { text: "Октябрь", value: "oct" },
            { text: "Ноябрь", value: "nov" },
            { text: "Декабрь", value: "dec" },
            { text: "Действие", value: "actions", sortable: false }
          ];
    },
    computedItems() {
      return this.mainTemplate.price_type === "weekdays"
        ? this.mainTemplate.weekdays
        : this.mainTemplate.months;
    }
  },
  created() {
    if (!this.is_edit) this.initializeTable();
  },
  methods: {
    initializeTable() {
      this.mainTemplate.months = [];
      this.mainTemplate.weekdays = [];
      this.priceTypes.forEach(priceType => {
        this.mainTemplate.months.push({
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
          price_type: priceType.value,
          name: priceType.name
        });
        this.mainTemplate.weekdays.push({
          monday_price: 0,
          tuesday_price: 0,
          wednesday_price: 0,
          thursday_price: 0,
          friday_price: 0,
          saturday_price: 0,
          sunday_price: 0,
          price_type: priceType.value,
          name: priceType.name
        });
      });
    },
    // когда меняется категория, хендлить тип измененной категории
    onChangeCategory() {
      this.categoryList.map(el => {
        if (this.mainTemplate.category == el.id) {
          if (el.category_type == "only_day") {
            this.mainTemplate.weekdays = this.mainTemplate.weekdays.filter(
              week => {
                return !["time", "time_adult", "time_child"].includes(
                  week.price_type
                );
              }
            );
            this.mainTemplate.months = this.mainTemplate.months.filter(week => {
              return !["time", "time_adult", "time_child"].includes(
                week.price_type
              );
            });
          } else if (el.category_type == "only_time") {
            this.mainTemplate.weekdays = this.mainTemplate.weekdays.filter(
              week => {
                return [
                  "time",
                  "time_adult",
                  "time_child",
                  "koika",
                  "child_without_place"
                ].includes(week.price_type);
              }
            );
            this.mainTemplate.months = this.mainTemplate.months.filter(week => {
              return [
                "time",
                "time_adult",
                "time_child",
                "koika",
                "child_without_place"
              ].includes(week.price_type);
            });
          } else {
            if (!this.is_edit) this.initializeTable();
          }

          if (this.mainTemplate.price_type === "weekdays") {
            // реактивность плохо работает, поэтому так решил сделать
            this.mainTemplate.price_type = "period";
            this.mainTemplate.price_type = "weekdays";
          }
          if (this.mainTemplate.price_type === "period") {
            this.mainTemplate.price_type = "weekdays";
            this.mainTemplate.price_type = "period";
          }
          this.$forceUpdate();
          this.reactTrigger++;
        }
      });
    },
    // открыть попап определенной Типа цены = { Основной тариф, ... }
    editItem(item) {
      if (this.mainTemplate.price_type === "weekdays") {
        this.editedWeekItem = Object.assign({}, item);
        this.weekDialog = true;
      } else {
        this.editedMonthItem = Object.assign({}, item);
        this.monthDialog = true;
      }
    },
    // сохранить содержимое попапа либо локально если еще не создался тариф, либо делая запрос если тариф уже есть
    async saveWeek() {
      this.mainTemplate.weekdays.forEach((week, week_index) => {
        if (week.name == this.editedWeekItem.name) {
          this.mainTemplate.weekdays[week_index] = JSON.parse(
            JSON.stringify(this.editedWeekItem)
          );
          this.$forceUpdate();
        }
      });

      if (this.is_edit) {
        this.disabledBtn = true;
        try {
          await TariffsApiUrls.setWeekdays(
            this.editedWeekItem.id,
            this.editedWeekItem
          );
        } finally {
          this.disabledBtn = false;
        }
      }
      this.weekDialog = false;
      this.reactTrigger++;
    },
    // сохранить содержимое попапа либо локально если еще не создался тариф, либо делая запрос если тариф уже есть
    async saveMonth() {
      this.mainTemplate.months.forEach((month, month_index) => {
        if (month.name == this.editedMonthItem.name) {
          console.log(this.editedMonthItem);
          this.mainTemplate.months[month_index] = JSON.parse(
            JSON.stringify(this.editedMonthItem)
          );
          this.$forceUpdate();
        }
      });

      if (this.is_edit) {
        this.disabledBtn = true;
        try {
          await TariffsApiUrls.setMonth(
            this.editedMonthItem.id,
            this.editedMonthItem
          );
        } finally {
          this.disabledBtn = false;
        }
      }
      this.monthDialog = false;
      this.reactTrigger++;
    }
  }
};
</script>

<style scoped></style>
