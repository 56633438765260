<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :single-select="true"
        show-select
        sort-by="category"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-if="dialog" v-slot:top>
          <v-toolbar flat color="white">
            <v-spacer />
            <v-dialog v-model="dialog" max-width="1200px">
              <v-card>
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step
                      class="cursor_pointer"
                      step="1"
                      @click="e1 = 1"
                    >
                      Основная информация
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                      class="cursor_pointer"
                      step="2"
                      @click="e1 = 2"
                    >
                      Тарифное условие
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step
                      class="cursor_pointer"
                      step="3"
                      @click="e1 = 3"
                    >
                      Услуги
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-content step="1">
                    <first-step
                      :is_edit="is_edit"
                      :category-list="categoryList"
                      :mainTemplate="editedItem"
                      ref="firststep"
                    />
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              type="number"
                              v-model.number="editedItem.min_days"
                              label="Минимальный срок бронирования"
                              dense
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              type="number"
                              v-model.number="editedItem.advance_booking"
                              label="При предварительном бронировании"
                              dense
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="editedItem.discount_type"
                              label="Тип"
                              :items="discountTypes"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              type="number"
                              v-model.number="editedItem.discount_count"
                              style="padding-top: 17px"
                              label="Скидка"
                              dense
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              type="number"
                              v-model.number="editedItem.min_days_between"
                              style="padding-top: 17px"
                              label="Минимальное свободного количество дней между бронями"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col class="pb-0 pt-0" cols="6">
                            <v-select
                              v-model="editedItem.early_check_in"
                              item-text="text"
                              item-value="value"
                              :items="discountList"
                              label="Ранний заезд 50% от стоимости"
                              dense
                            />
                          </v-col>
                          <v-col class="pb-0 pt-0" cols="6">
                            <v-select
                              v-model="editedItem.late_check_out"
                              :items="discountList"
                              label="Поздний выезд 50%"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-stepper-content>
                </v-stepper>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="close"> Отмена </v-btn>
                  <v-btn
                    v-if="e1 === 3"
                    color="primary"
                    text
                    @click="update"
                    :disabled="disabledBtn"
                  >
                    Сохранить
                  </v-btn>
                  <v-btn v-else color="primary" text @click="nextStep">
                    Далее
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn class="ma-3" color="primary" @click="createTariff">
        Создать тариф
      </v-btn>
    </v-card>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Вы уверены? </v-card-title>
        <v-card-text>Тариф удалится безвозвратно!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            Отмена
          </v-btn>
          <v-btn color="green darken-1" text @click="remove"> Согласен </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FirstStep from "../components/tariff/FirstStep";
import toast from "../../../plugins/toast";
import { TariffsApiUrls } from "@/services/accommodationRequests/tariffs.api.js";
import { CategoriesApiUrls } from "@/services/accommodationRequests/categories.api.js";
import moment from "moment";

export default {
  name: "Tariff",
  components: {
    FirstStep
  },
  data: () => ({
    disabledBtn: false,
    firstWatcherTrigger: false,
    totalItems: 0,
    options: {},
    tariffForDelete: null,
    dialogDelete: false,
    is_edit: false,
    e1: 1,
    dialog: false,
    headers: [
      { text: "Категория", value: "name", class: "table-title" },
      {
        text: "Тип оплаты",
        value: "price_type",
        sortable: true,
        class: "table-title"
      },
      {
        text: "Дата создания",
        value: "created_at",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Действие",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    discountTypes: [
      { text: "Фикс.", value: "fixed" },
      { text: "%", value: "percent" }
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      name: null,
      company: null,
      category: null,
      tariff_type: "period",
      week: null,
      month: null,
      min_days: 0,
      advance_booking: 0,
      discount_type: "fixed",
      early_check_in: 0,
      late_check_out: 0,
      months: [],
      weekdays: [],
      price_type: "",
      min_days_between: 0,
      discount_count: 0
    },
    categoryList: [],
    discountList: [
      { text: "0", value: 0 },
      { text: "10%", value: 10 },
      { text: "20%", value: 20 },
      { text: "30%", value: 30 },
      { text: "40%", value: 40 },
      { text: "50%", value: 50 },
      { text: "60%", value: 60 },
      { text: "70%", value: 70 },
      { text: "80%", value: 80 },
      { text: "90%", value: 90 },
      { text: "100%", value: 100 }
    ],
    priceTypes: [
      { name: "Основной тариф", value: "base" },
      { name: "Доп. место взрослый", value: "additional" },
      { name: "Доп. место детский", value: "child" },
      { name: "Одиночное бронирование", value: "single" },
      { name: "По часам", value: "time" },
      { name: "По взрослым часам", value: "time_adult" },
      { name: "По детским часам", value: "time_child" },
      { name: "По койкам", value: "koika" },
      { name: "Дети без места", value: "child_without_place" }
    ],
    loading: false
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.loadRow();
    this.initializeTable();
  },
  methods: {
    // добавить в editedItem months, weekdays
    initializeTable() {
      this.editedItem.months = [];
      this.editedItem.weekdays = [];
      this.priceTypes.forEach(priceType => {
        this.editedItem.months.push({
          jan: 0,
          feb: 0,
          mar: 0,
          apr: 0,
          may: 0,
          jun: 0,
          jul: 0,
          aug: 0,
          sep: 0,
          oct: 0,
          nov: 0,
          dec: 0,
          price_type: priceType.value,
          name: priceType.name
        });
        this.editedItem.weekdays.push({
          monday_price: 0,
          tuesday_price: 0,
          wednesday_price: 0,
          thursday_price: 0,
          friday_price: 0,
          saturday_price: 0,
          sunday_price: 0,
          price_type: priceType.value,
          name: priceType.name
        });
      });
    },
    // добавить в уже готовый editedItem с левой стороны названия { Основной тариф, ... }
    addLeftNames() {
      this.priceTypes.forEach(priceType => {
        this.editedItem.months.forEach(month => {
          if (month.price_type === priceType.value) month.name = priceType.name;
        });
        this.editedItem.weekdays.forEach(week => {
          if (week.price_type === priceType.value) week.name = priceType.name;
        });
      });
    },
    // delete tariff
    async remove() {
      this.dialogDelete = false;
      await TariffsApiUrls.deleteTariff(this.tariffForDelete);
      this.items = [];
      this.loadRow();
    },
    // переход на следующий step в модалке
    nextStep() {
      switch (this.e1) {
        case 1:
          if (!this.editedItem.category) {
            toast.error("Выберите категорию");
            return;
          }
          break;
        case 2:
          const min_days = this.editedItem.min_days < 0;
          const advance_booking = this.editedItem.advance_booking < 0;
          const discount_count = this.editedItem.discount_count < 0;
          if (min_days || advance_booking || discount_count) {
            toast.error("Заполните все данные");
            return;
          }
          break;
        case 3:
          const early_check_in = this.editedItem.early_check_in < 0;
          const late_check_out = this.editedItem.late_check_out < 0;
          if (early_check_in || late_check_out) {
            toast.error("Заполните все данные");
            return;
          }
          break;
        default:
      }
      this.e1 = this.e1 + 1;
    },
    // загрузить тарифы и категории
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      let res = await TariffsApiUrls.getTariffs(params);
      this.totalItems = res.meta.pagination.count;
      res.results.forEach(item => {
        item.created_at = moment(item.created_at * 1000).format("DD.MM.YYYY");
        item.price_type =
          item.price_type === "weekdays" ? "По дням недели" : "За период";
      });
      this.items = res.results;
      let params_2 = {
        "page[size]": 10000,
        "page[number]": 1
      };
      this.loading = false;

      let response = await CategoriesApiUrls.getCategories(params_2);
      response.forEach(item => {
        item.value = item.id;
        item.text = item.name;
      });
      this.categoryList = response;
    },
    // открыть модалку с квизом для создания тарифа
    createTariff() {
      this.is_edit = false;
      this.dialog = true;
      this.editedItem = {
        id: null,
        name: null,
        company: null,
        category: null,
        tariff_type: "period",
        week: null,
        month: null,
        min_days: 0,
        advance_booking: 0,
        discount_type: "fixed",
        early_check_in: 0,
        late_check_out: 0,
        months: [],
        weekdays: [],
        price_type: "",
        min_days_between: 0,
        discount_count: 0
      };
    },
    // открыть модалку с квизом для изменения определенного тарифа
    async editItem(item) {
      this.initializeTable();
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      let resp = await TariffsApiUrls.getTariff(this.editedItem.id);
      this.editedItem = { ...resp };
      this.addLeftNames();
      this.$forceUpdate();

      this.dialog = true;
      this.is_edit = true;
      setTimeout(() => {
        this.$refs.firststep.onChangeCategory();
      }, 0);
    },
    // открыть модалку для удаления тарифа
    deleteItem(item) {
      this.dialogDelete = true;
      this.tariffForDelete = item.id;
    },
    // закрыть модалку
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    // создать или изменить запросы тарифа
    async update() {
      const min_days = this.editedItem.min_days < 0;
      const advance_booking = this.editedItem.advance_booking < 0;
      const discount_count = this.editedItem.discount_count < 0;
      const early_check_in = this.editedItem.early_check_in < 0;
      const late_check_out = this.editedItem.late_check_out < 0;
      if (
        early_check_in ||
        late_check_out ||
        min_days ||
        advance_booking ||
        discount_count
      ) {
        toast.error("Заполните все данные");
        return;
      }

      if (this.editedIndex > -1) {
        delete this.editedItem.category;
        this.disabledBtn = true;

        try {
          await TariffsApiUrls.setTariff(this.editedItem.id, this.editedItem);
          toast.success("Успешно изменено!");
          this.disabledBtn = false;
          this.items = [];
          this.loadRow();
          this.close();
        } catch (e) {
          this.disabledBtn = false;
        }
      } else {
        const obj = [{ id: this.editedItem.category }];
        if (!this.editedItem.category) {
          toast.error("Выберите категорию");
          return;
        }
        var result = this.categoryList.filter(function(v) {
          return obj.some(function(v2) {
            return v.value === v2.id ? v.text : "";
          });
        });
        this.editedItem.name = result[0].text;
        this.disabledBtn = true;
        try {
          await TariffsApiUrls.createTariff(this.editedItem);
          toast.success("Успешно создано!");
          this.disabledBtn = false;
          this.items = [];
          this.loadRow();
          this.close();
        } catch (e) {
          this.disabledBtn = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.cursor_pointer {
  cursor: pointer;
}
</style>
