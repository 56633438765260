const axios = window.axios
const url = '/api/v2/tariffs/'
const urlMonths = '/api/v2/tariffs/months/'
const urlWeekdays = '/api/v2/tariffs/weekdays/'
const urlAdditionals = '/api/v2/tariffs/additionals/'



export const TariffsApiUrls = {
    // tariffs настройка тарифов лист тарифов
    async getTariffs(params){
        return (await axios.get(`${url}`, { params: params })).data;
    },
    async createTariff(body){
        return (await axios.post(`${url}`, body)).data;
    },
    async getTariff(id){
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setTariff(id, body){
        return (await axios.put(`${url}${id}/`, body)).data;
    },
    async deleteTariff(id){
        return (await axios.delete(`${url}${id}/`)).data;
    },


    // months два типа
    async getMonth(id){
        return (await axios.get(`${urlMonths}${id}/`)).data;
    },
    async setMonth(id, body){
        return (await axios.patch(`${urlMonths}${id}/`, body)).data;
    },


    // weekdays два типа
    async getWeekdays(id){
        return (await axios.get(`${urlWeekdays}${id}/`)).data;
    },
    async setWeekdays(id, body){
        return (await axios.patch(`${urlWeekdays}${id}/`, body)).data;
    },



    // additionals доп тариф
    async getAdditionals(params){
        return (await axios.get(`${urlAdditionals}`, { params: params })).data;
    },
    async createAdditional(body){
        return (await axios.post(`${urlAdditionals}`, body)).data;
    },
    async getAdditional(id){
        return (await axios.get(`${urlAdditionals}${id}/`)).data;
    },
    async setAdditional(id, body){
        return (await axios.put(`${urlAdditionals}${id}/`, body)).data;
    },
    async deleteAdditional(id){
        return (await axios.delete(`${urlAdditionals}${id}/`)).data;
    },
}